// This file will contains variables used for colors and media query sizing accross the SCSS files

$RR-green: #216600;
$RR-green-hover: #009c06;
$RR-black: #222;
$RR-white: #fff;
$RR-grey: #f7f7f7;
$RR-text: #4a4a4a;
$RR-text-light: #a3a3a3;
$RR-blue: #004cd5;
$RR-blue-hover: #1e3799;
$RedeemNow: #f8bf00;
$dfltBlack: #222;
$dark: $dfltBlack;
$primary: $RR-green;
$flashRed: #b60000;

$desktopPadding: 4vw;
$largeDesktopPadding: 5vw;

$mobileBreakpoint: 420px;
$tabletBreakpoint: 650px;
$tabletBreakpointMax: 768px;
$desktopBreakpoint: 1279px;
$largeDesktopBreakpoint: 1700px;

$desktopTopPadding: 90px;

@mixin text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Snippet styles for Hero promo banner */
.hban__promo--mobile {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top:75px;
  }
  
  .hban__promo__img--mobile {
    height: auto;
    display: flex;
    background-size: 100% 100%;
    margin: 0 auto;
  }
  h1.hban__hdr--mobile {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
  }
  h1.hban__hdr--mobile:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 10%;
    padding-top: 10px;
    border-bottom: 1.5px solid #f7bf2c;
  }
  /* End of Snippet styles for Hero promo banner */
@import '../_variables.scss';

@mixin buttonStyling($bgColor) {
    background-color: $bgColor;
    border: none;
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    min-width: 150px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.18);

    @media screen and (min-width: $desktopBreakpoint) {
        height: 50px;
    }
}
// On review page in the pricing component centers the CTA
@mixin reviewPageCenter() {
    margin: auto;
    display: flex;
    justify-content: center;
}
@mixin buttonText($color) {
    font-size: 20px;
    color: $color;
    &:visited {
        color: $color;
    }
}
.large {
    width: 205px;
}
.visitSiteCTA,
.viewDiscountCTA {
    @include buttonStyling($RR-green);
    a {
        @include buttonText(#fff);
    }
}

.redeemNowCTA {
    @include buttonStyling($RedeemNow);
    @include buttonText(#000);
}

#reviewPage {
    .visitSiteCTA,
    .viewDiscountCTA {
        @include buttonStyling($RR-green);
        @include reviewPageCenter();
        a {
            @include buttonText(#fff);
        }
    }
    .redeemNowCTA {
        @include buttonStyling($RedeemNow);
        @include reviewPageCenter();
        @include buttonText(#000);
    }
}

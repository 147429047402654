html,
body {
    padding: 0;
    margin: 0;
    font-family:
        'Roboto',
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Helvetica Neue',
        Arial,
        sans-serif;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;

    color: #4a4a4a;
    scroll-padding-top: 110px; /* height of sticky header */
    scroll-behavior: smooth;
}
@media only screen and (max-width: 1279px) {
    .noScroll {
        overflow: hidden;
        touch-action: none;
        -ms-touch-action: none;
        overflow-y: hidden;
    }
}
* {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

button {
    border: none;
    text-align: center;
}
.button {
    background-color: #fff;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding: calc(0.5em - 1px) 1em;
    text-align: center;
    white-space: nowrap;
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    line-height: 1.5;
    user-select: none;
}
.goToTop {
    top: auto;
    right: 15px;
    bottom: 15px;
    position: fixed;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    z-index: 11;
    background-color: #44bd32;
    border-radius: 5px;
    color: #fff;
    opacity: 0.5;
    text-align: center;
    padding: 15px;
    text-decoration: none;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

ul {
    list-style: none;
}

button,
input,
select,
textarea {
    margin: 0;
}

html {
    box-sizing: border-box;
}

img,
video {
    height: auto;
    max-width: 100%;
}

iframe {
    border: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

.mx-auto {
    margin: auto;
}

.ml-10 {
    margin-left: 10px;
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.j-end {
    justify-content: flex-end;
}

.j-center {
    justify-content: center;
}

/*True global CSS*/
.modal {
    display: none;
}

.bold {
    font-weight: 700;
}

.indent {
    padding: 80px 5vw 0px 5vw;
}

.cap1000 {
    max-width: 1000px;
    margin: auto;
}

.hidden,
.is-hidden {
    display: none;
}

a {
    color: var(--color-216600-419d16);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'roboto condensed';
}

h2 {
    font-size: 24px;
}

p,
li {
    font-family: arial, sans-serif;
}

p {
    font-size: 17px;
}

li::marker {
    color: #666;
}

.disclaimerText {
    font-size: 13px;
    font-weight: bold;
    color: red;
}

.disclaimerMessage {
    font-size: 13px;
    font-weight: bold;
    color: #44bd32;
}

.googleLogin {
    border: 2px solid #f0f0f0 !important;
    color: #4a4a4a !important;
    box-shadow: none !important;
}

/* auth pages */
.auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 50vh;
    padding: 0 50px;
    margin-top: 10vh;
    font-family: arial, sans-serif;
}
.auth .section-content {
    padding-top: 10px;
}
.auth input {
    margin: 20px;
}
.auth button {
    background: #44bd32;
    padding: 10px;
    color: white;
    border-radius: 3px;
}
.homepageTagCloud {
    margin-top: 5px;
}
@media only screen and (min-width: 1100px) {
    html,
    body {
        overflow-x: visible;
        overflow-y: visible;
    }
}

#abtest-hide-1,
#abtest-hide-2,
#abtest-hide-3 {
    display: block;
}
#abtest-show-1,
#abtest-show-2,
#abtest-show-3 {
    display: none;
}
/* 
The theme variable are built using:
E.g --color-lightColor-darkColor
} */
[data-theme='light'] {
    body,
    html {
        background: white;
    }
    --color-d1ecf1-292929: #d1ecf1;
    --color-fff-292929: #fff;
    --color-fff-000: #fff;
    --color-fff-1a1a1a: #fff;
    --color-fff-121212: #fff;
    --color-fff-1b1b1b: #fff;

    --color-f5f5f5-7a7a7a: #f5f5f5;
    --color-f5f5f5-111: #f5f5f5;
    --color-f5f5f5-292929: #f5f5f5;
    --color-f5f5f5-121212: #f5f5f5;
    --color-f5f5f5-000: #f5f5f5;
    --color-f5f5f5-1c1c1: #f5f5f5;
    --color-f7f7f7-121212: #f7f7f7;
    --color-ebfaef-121212: #ebfaef;
    --color-ececec-000: #ececec;
    --color-ececec-292929: #ececec;
    --color-ececec-111: #ececec;

    --color-000-fff: #000;
    --color-ccc-121212: #ccc;
    --color-CCC-fff: #ccc;
    --color-CCC-f5f: #ccc;
    --color-CCC-1a1a1a: #ccc;
    --color-CCC-7A7A7A: #ccc;
    --color-CCC-292929: #ccc;

    --color-000-f5f: #000;
    --color-000-ccc: #000;
    --color-000-a3a3a3: #000;
    --color-4a4a4a-a3a3a3: #4a4a4a;
    --color-4a4a4a-fff: #4a4a4a;

    --color-leftMenuLight-leftMenuDark: linear-gradient(180deg, rgba(204, 204, 204, 0.5) 0%, rgba(245, 245, 245, 0.5) 100%);
    --color-cardShadowLight-cardShadowDark: drop-shadow(0 0 3px #cccccc);
    --bg-review-rail: rgba(245, 245, 245, 0.5);
    --bg-flashRed: #b60000;

    --color-292929-000: #292929;
    --color-292929-f5f5f5: #292929;
    --color-292929-CCC: #292929;
    --color-292929-ECECEC: #292929;
    --color-292929-a3a3a3: #292929;

    --color-696969-fff: #696969;
    --color-696969-a3a3a3: #696969;
    --color-696969-292929: #696969;
    --color-747474-a3a3a3: #747474;
    --color-a3a3a3-f5f5f5: #a3a3a3;
    --color-a3a3a3-121212: #a3a3a3;
    --color-a3a3a3-fff: #a3a3a3;
    --color-a3a3a3-292929: #a3a3a3;
    --color-a3a3a3-4d4d4d: #a3a3a3;

    --color-4d4d4d-ECECEC: #4d4d4d;
    --color-4d4d4d-292929: #4d4d4d;
    --color-4d4d4d-CCCCC: #4d4d4d;
    --color-rgba01-292929: rgba(0, 0, 0, 0.1);
    --color-4d4d4d-fff: #4d4d4d;
    --color-4d4d4d-a3a3a3: #4d4d4d;
    --color-4d4d4d-f5f5f5: #4d4d4d;
    --color-f5f5f5-1a1a1a: #f5f5f5;
    --color-f7f7f7-000: #f7f7f7;

    --color-676767-a3a3a3: #676767;

    --color-e13a3a-e55555: #e13a3a;
    --color-388713-419d16: #388713;
    --color-388713-004CD5: #388713;
    --color-2f72eb-4b85ee: #2f72eb;
    --color-666-216600: #666;
    --color-216600-4b85ee: #216600;
    --color-216600-419d16: #216600;
    --color-cccccc-4d4d4d: #cccccc;
    --color-cccccc-292929: #cccccc;

    --color-004cd5-2F72EB: #004cd5;
    --color-004CD5-4B85EE: #004cd5;

    --color-d1ecf1-0C5460: #d1ecf1;
    --color-0C5460-d1ecf1: #0c5460;
}

[data-theme='dark'] {
    body,
    html {
        background-color: black;
    }
    --color-d1ecf1-292929: #292929;
    --color-fff-292929: #292929;
    --color-fff-000: #000;
    --color-fff-1a1a1a: #1a1a1a;
    --color-fff-121212: #121212;
    --color-fff-1b1b1b: #1b1b1b;

    --color-f5f5f5-7a7a7a: #7a7a7a;
    --color-f5f5f5-111: #111;
    --color-f5f5f5-292929: #292929;
    --color-f5f5f5-121212: #121212;
    --color-f5f5f5-000: #000;
    --color-f5f5f5-1c1c1: #1c1c1c;
    --color-f7f7f7-121212: #121212;
    --color-ebfaef-121212: #121212;
    --color-ececec-000: #000;
    --color-ececec-292929: #292929;
    --color-ececec-111: #111;

    --color-000-fff: #fff;
    --color-ccc-121212: #121212;
    --color-CCC-fff: #fff;
    --color-CCC-f5f: #f5f5f5;
    --color-CCC-1a1a1a: #1a1a1a;
    --color-CCC-7A7A7A: #7a7a7a;
    --color-CCC-292929: #292929;
    --color-000-f5f: #f5f5f5;
    --color-000-ccc: #ccc;
    --color-000-a3a3a3: #a3a3a3;

    --color-4a4a4a-a3a3a3: #a3a3a3;
    --color-4a4a4a-fff: #fff;

    --color-leftMenuLight-leftMenuDark: linear-gradient(180deg, rgba(40, 40, 40, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
    --color-cardShadowLight-cardShadowDark: drop-shadow(0px 0px 9px 3px rgba(0, 0, 0, 0.6));
    --bg-review-rail: linear-gradient(180deg, #282828 0%, rgba(0, 0, 0, 0.5) 100%);
    --bg-flashRed: #b60000;

    --color-292929-000: #000;
    --color-292929-f5f5f5: #f5f5f5;
    --color-292929-CCC: #ccc;
    --color-292929-ECECEC: #ececec;
    --color-292929-a3a3a3: #a3a3a3;

    --color-696969-fff: #fff;
    --color-696969-a3a3a3: #a3a3a3;
    --color-696969-292929: #292929;
    --color-747474-a3a3a3: #a3a3a3;
    --color-a3a3a3-f5f5f5: #f5f5f5;
    --color-a3a3a3-121212: #121212;
    --color-a3a3a3-292929: #292929;
    --color-a3a3a3-4d4d4d: #4d4d4d;

    --color-a3a3a3-fff: #fff;

    --color-4d4d4d-ECECEC: #ececec;
    --color-4d4d4d-292929: #292929;
    --color-rgba01-292929: #292929;
    --color-4d4d4d-CCCCC: #cccccc;

    --color-4d4d4d-fff: #fff;
    --color-4d4d4d-a3a3a3: #a3a3a3;
    --color-4d4d4d-f5f5f5: #f5f5f5;
    --color-f5f5f5-1a1a1a: #1a1a1a;
    --color-f7f7f7-000: #000;

    --color-676767-a3a3a3: #a3a3a3;

    --color-e13a3a-e55555: #e55555;
    --color-388713-419d16: #419d16;
    --color-388713-004CD5: #004cd5;
    --color-2f72eb-4b85ee: #4b85ee;
    --color-666-419d16: #419d16;
    --color-216600-4b85ee: #4b85ee;
    --color-216600-419d16: #419d16;
    --color-cccccc-4d4d4d: #4d4d4d;
    --color-cccccc-292929: #292929;

    --color-004cd5-2F72EB: #2f72eb;
    --color-004CD5-4B85EE: #4b85ee;

    --color-d1ecf1-0C5460: #0c5460;
    --color-0C5460-d1ecf1: #d1ecf1;
}

[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-size: 10px 10px;
}

.search-overlay {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    width: 100%;
    height: calc(100dvh - 60px);
    max-height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background: var(--color-ececec-000);
    z-index: 25;
}

.search-message {
    margin: 5vw auto;
    padding: 20px 15px;
    color: var(--color-4a4a4a-a3a3a3);

    a {
        cursor: pointer;
    }
    button {
        color: #006ab9;
        font-size: 17px;
        font-weight: bold;
        background-color: transparent;
    }
}
.search-bar {
    display: flex;
    height: 40px;
    padding-left: 5px;
    align-items: flex-end;
    gap: 10px;
    flex: 1 0 0;
    width: 100%;

    border-bottom: 2px solid var(--color-696969-a3a3a3);
    background: var(--color-fff-000);

    .ais-Stats-text {
        color: var(--color-000-fff);
    }
    .stats {
        color: var(--color-000-fff);
    }
}
.ais-SearchBox-submitIcon {
    display: none;
}

.search-form textarea,
.search-form input.text,
.search-form input[type='text'],
.search-form input[type='button'],
.search-form input[type='submit'] {
    -webkit-appearance: none;
    border-radius: 0;
}
.search-form input[type='search']::-webkit-search-decoration,
.search-form input[type='search']::-webkit-search-cancel-button,
.search-form input[type='search']::-webkit-search-results-button,
.search-form input[type='search']::-webkit-search-results-decoration {
    display: none;
}

.search-form button,
.search-form svg {
    background-color: transparent;
    cursor: pointer;
}
.search-form input {
    border: none;
    background: transparent !important;
    height: 40px;
    width: 100% !important;
    font-size: 16px;
    min-width: 80px;
    border-radius: 6px 0px 0px 6px;
    color: var(--color-292929-f5f5f5);
}

.search-form .submitIcon,
.search-form .resettIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    stroke: var(--color-292929-f5f5f5) !important;
    background: transparent;
}

.search-form {
    display: flex;
    color: var(--color-292929-f5f5f5);
}
.ais-Hits {
    max-width: 2000px;
    margin: 0 auto;
    width: 100%;
}

.ais-Hits-list {
    display: flex;
    justify-content: center;
    column-gap: 3rem;

    flex-flow: row wrap;
    padding: 0 15px 20px 15px;
}
.ais-Hits-item {
    list-style: none;
    margin: 25px 0;
}

.ais-Pagination {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ais-Pagination-list {
    width: 100%;
    display: flex;
    overflow-x: auto;
}
@media only screen and (min-width: 450px) {
    .search-bar .search-bar {
        display: flex;
        border-top: none;
        border-right: none;
        border-left: none;
        max-width: 360px;
    }
    .ais-Hits-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
    .ais-Hits-item {
        max-width: 450px;
        margin: 25px 1.2vw;
    }
}

@media only screen and (min-width: 650px) {
    .ais-Hits-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .ais-Hits-item {
        width: 45%;
        margin: 25px 0;
    }
}

@media only screen and (min-width: 650px) and (max-width: 1279px) {
    .search-bar .search-form {
        display: flex;
        min-width: 325px;
    }
    .ais-Hits-list {
        max-width: 1000px;
        margin: auto;
    }
}
@media only screen and (min-width: 1000px) {
    .ais-Hits-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
}

@media only screen and (min-width: 1280px) {
    .search-bar .search-form {
        display: flex;
        min-width: 300px;
    }
    .ais-Hits-list {
        max-width: 1000px;
        margin: auto;
    }
}

@media only screen and (min-width: 1700px) {
    .ais-Hits-list {
        max-width: 1500px;
    }

    .ais-Hits-item {
        margin: 1.2vw 20px;
        width: 30%;
    }
}

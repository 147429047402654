/* Snippet styles for Hero header quotes */
.hban {
    width: 100%;
    justify-content: center;
    .hban__lft {
      width: 100%;
      height: 100%;
      flex-direction: column;
      padding: 0 10px;
      background-image: linear-gradient(180deg,#ebedee,#f7f7f7);
      position: relative;
      display: flex;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      // box-shadow: 0 0 13px rgb(0 0 0 / 40%);
    }
    .hban__corner-star {
      background-repeat: no-repeat;
      position: absolute;
      right: -1px;
      top: -1px;
    }
    .hban__hdr{
      margin-top: 15px;
      margin-bottom: 5px;
      font-size: 24px;
      text-align: center;
      color: #212121;
      font-weight: 700;
    }
    .hban__p{
      font-size: 14px;
      text-align: center;
      color: #818181;
      word-spacing: .04em;
      font-weight: 500;
    }
    .hban__lnks{
      flex-direction: column;
      margin: 15px auto;
      padding-left: 0;
      list-style: disc;
      font-size: 17px;
      .hban__lnk:nth-child(1),.hban__lnk:nth-child(6){
        display:none;
      }
      a{
        color:#1B9CFC;
      }
    }
  }
  /*End of Snippet styles for Hero header quotes */